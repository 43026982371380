import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

import SVGSeparator from '../components/project-blocks/separator'
import InformationSectionBlock from '../components/project-blocks/information-section'
import TextAndImageBlock from '../components/project-blocks/text-and-image'
import TextLargeAndSmallBlock from '../components/project-blocks/text-large-and-small'

import { informationSectionData } from '../data/case-study-data--homeward'

// import wanderHomeHeroImage from '../images/wander-home--sketches.png'
// import wanderHomeIcon from '../images/wander-home--icon.png'
// import wanderHomeInitialSketches from '../images/wander-home--initial-sketches.png'
// import wanderHomeVisualResearch from '../images/wander-home--visual-research.png'
// import wanderHomePrototypeOne from '../images/wander-home--prototype-one.png'
// import wanderHomeRefinements from '../images/wander-home--refinements.png'
// import wanderHomePrototypeFinal from '../images/wander-home--prototype-final.png'

// import ProjectStyles from '../components/project-styles.module.less'

const HeaderImage = styled.div`
	height: auto;

	margin-bottom: 12px;

	& > * {
		width: auto;
		max-height: 100vh;
		/* height: fill-available; */
	}
`

const HeadingGroup = styled.header`
	display: grid;
	grid-gap: 6px;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 1px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Subtitle = styled.h2`
	font-size: 18px;
	opacity: 0.75;

	@media (min-width: 768px) {
		font-size: 21px;
	}
`

const WanderHome = ({ data }) => (
	<Layout>
		<SEO title="Homeward Device Companion App UX / UI Case Study" />
		<HeaderImage>
			<Img
				fluid={data.heroImage.childImageSharp.fluid}
				alt="Homeward Sketches."
				imgStyle={{ objectFit: 'cover' }}
			/>
		</HeaderImage>
		<HeadingGroup>
			<Title>Homeward</Title>
			<Subtitle>Device Companion App UX/UI Case Study</Subtitle>
		</HeadingGroup>
		<SVGSeparator />
		<InformationSectionBlock data={informationSectionData} />
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Background'}
			paragraphs={[
				'The world population is getting older. Our parents and grandparents are also living longer. With that comes new challenges in medicine and society. How do we best care for people who can no longer perform all of the tasks they used to? Those who need around the clock care? Those who have memory loss?',
				'One of the symptoms that arises as a result of dimentia, Alzheimer’s Disease is that patients tend to wander. The science is still on going as to why these people wander but when these people realize they are not in familiar territory it is very stressful.',
				'Rather than being picked up by a emergency worker, such as a police officer, fire fighter, or ambulance, a better option is to gently be guided back to their residence by a device and companion app. This solution seeks to locate the person by GPS, let them talk to a caregiver via the app, and guide them gently back home.'
			]}
			imageData={data.backgroundInfoImage}
		/>
		<SVGSeparator />
		<TextLargeAndSmallBlock
			heading={'The Process'}
			paragraphs={[
				'This project was completed for the course “YSDN 3006 — Advanced Models and Speculative Interfaces - Visual Interaction Design.” A course focused on generating new data visualization models past the usual bar charts, pie graphs, and other excel templates. The brief for the project was to produce an internet connected device that has a mobile companion app.'
			]}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Product Features & Attributes'}
			paragraphs={[
				'People with dementia tend to wander. The physical product is a activity tracker that is worn on the wrist (as a watch or bracelet) or on the finger as a ring; whatever is the most familiar to the resident. The physical device will collect data such as heart rate, activity (motion, steps, etc.), sleep data, and location. This device will also have a speaker and microphone for two-way communication to and from the resident to the caregivers.',
				'That data is collected then processed and visualized in the companion app to give the caretaker the most important and accurate representation of their residents’ emotional and physical health to lessen the changes of wandering but also to deal with the wander when it occurs.'
			]}
			imageData={data.sketches}
			altText={''}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Initial sketches, lo-fi wireframes'}
			paragraphs={[
				'The main data visualization is an atomic model of people’s safety. If you remember back to high school science, the Rutherford model acts as a conceptual basis behind the visual. The further the resident is from the center the more attention is deserved by the caregivers.',
				'The secondary visualization is a modified scatter-plot. The resident’s health is represented on x and y axes showing the emotional well-being on the x-axis and the physical well- being being shown on the y-axis.',
				'The tertiary visualization is a series of radar charts to display the resident’s changes over time and the caretaker can adjust things if necessary.'
			]}
			imageData={data.intialSketches}
			altText={''}
			switch={true}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Visual Research'}
			paragraphs={[
				'For the visual research, I scoured dribbble and Bēhance for ideas and general feel of healthcare and medical apps. I generally found lighter colour schemes with blues and corals and purples being dominant highlight/action colours.'
			]}
			imageData={data.visualResearch}
			altText={''}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Prototyping'}
			paragraphs={[
				'This is how the app looked when presented to the class on 2018-11-29. Since I lost a week due to travel, this was the first time I got real feedback on the visual models and processes I used inside the app. ',
				'Overall, it went well (even though I was in the throws of a pneumonia haze at the time). There were a few things that needed to be changed but the UI and UX were generally well received.',
				'The feedback was invaluable to change the thinking I had at the time of making the app prototype. Another eyes are necessary for design; the more sets of eyes, the better the design will be.'
			]}
			imageData={data.prototyping}
			altText={'An overview of the app\'s screens.'}
			switch={true}
			link={{
				label: 'Initial High Fidelity Sketch Prototype',
				url: 'https://sketch.cloud/s/GJZoO'
			}}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Refinements'}
			paragraphs={[
				'There were a few things that I needed to shore up for the final submission. To the right are the notes I took during the presentation.',
				'Some of the things that I needed to change were throwing out the dashboard for the main visualization screen, making the missing resident more prominent, and adding legends.'
			]}
			imageData={data.refinements}
			altText={'Castle\'s side drawer interface before refinements.'}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'The Solution'}
			paragraphs={[
				'I made quite a few refinements and changes to the V1 design. The most major being the dashboard change. This dashboard shows the caretaker more quickly what they need to know.',
				'I changed the colour indicators so they were more clear with explanations being found in the information button.',
				'The all residents pane has been taken out in favour of just touching the graph for the resident you want or using the dashboard screen if you know their name.',
				'I also decided to keep the double navigation. Yes, yes, it’s bad form but I defend it by saying that it is the clearest and quickest way of navigating between those patient’s graphs.'
			]}
			imageData={data.prototypeFinal}
			altText={'An overview of the app\'s complete screen layout.'}
			link={{
				label: 'Final Sketch Prototype',
				url: 'https://sketch.cloud/s/m8Rlz/l1GVVJv/play'
			}}
		/>
		<SVGSeparator />
		<Link to="/">← Back</Link>
	</Layout>
)

WanderHome.propTypes = {
	data: PropTypes.any.isRequired
}

export const pageQuery = graphql`
	query {
		heroImage: file(relativePath: { regex: "/homeward--hero-image.png/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		backgroundInfoImage: file(
			relativePath: { regex: "/wander-home--icon.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		sketches: file(relativePath: { regex: "/wander-home--sketches.png/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		intialSketches: file(
			relativePath: { regex: "/wander-home--initial-sketches.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		visualResearch: file(
			relativePath: { regex: "/wander-home--visual-research.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		prototyping: file(
			relativePath: { regex: "/wander-home--prototype-one.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		refinements: file(
			relativePath: { regex: "/wander-home--refinements.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		prototypeFinal: file(
			relativePath: { regex: "/wander-home--prototype-final.png/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

export default WanderHome
